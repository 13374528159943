<template>
  <div class="relative">
    <div
      @click="dropdown = !dropdown"
      v-click-outside="ddfalse"
      class="bg-primary text-white py-3 px-4 flex justify-between items-center border-r-4 border-orange"
    >
      <div class="flex items-center">
        <component
          v-if="component && pet.current_status != 100"
          :is="component"
          width="24"
          height="24"
          iconColor="#fff"
        />
        <span class="ml-2 text-base">{{ status }}</span>
      </div>
      <ArrowNext
        style="transform: rotate(90deg)"
        bgColor="transparent"
        iconColor="#fff"
      />
    </div>

    <ul
      v-if="dropdown"
      class="w-full mt-12 bg-greyishWhite outline border absolute -top-14"
    >
      <!-- Status103Icon HospitalizedIcon -->
      <div v-for="item in transition" :key="item.id">
        <SelectEach
          @click.native="
            selectStatus(
              item.nextStatusName,
              'Status' + item.nextStatusId + 'Icon',
              item.id,
              item.nextStatusId
            )
          "
          :title="item.nextStatusName"
          :component="'Status' + item.nextStatusId + 'Icon'"
        />
      </div>
    </ul>
  </div>
</template>

<script>
import SelectEach from "./SelectEach.vue";
import Status100Icon from "../../icons/Status103Icon.vue";
import Status101Icon from "../../icons/Status101Icon.vue";
import Status102Icon from "../../icons/Status102Icon.vue";
import Status103Icon from "../../icons/Status103Icon.vue";
import Status104Icon from "../../icons/Status104Icon.vue";
import Status105Icon from "../../icons/Status105Icon.vue";
import Status106Icon from "../../icons/Status106Icon.vue";
import Status107Icon from "../../icons/Status107Icon.vue";
import Status108Icon from "../../icons/Status108Icon.vue";
import Status109Icon from "../../icons/Status109Icon.vue";
import Status110Icon from "../../icons/Status110Icon.vue";
import ArrowNext from "../../icons/ArrowNext.vue";

export default {
  props: {
    pet: {},
    transition: [],
  },

  components: {
    SelectEach,
    Status100Icon,
    Status101Icon, //ForadoptIcon,
    Status102Icon, //ForsaleIcon,
    Status103Icon, //Status103Icon
    Status104Icon, //InboardingIcon
    Status105Icon, //IntransitIcon,
    Status106Icon, //Transfert Pending,
    Status107Icon, //Ownership Changed (external),
    Status108Icon, //LostIcon,
    Status109Icon, //Found,
    Status110Icon, //DeceasedIcon,
    ArrowNext,
  },
  data() {
    return {
      dropdown: false,
      status: this.pet.current_status_name, //"Select Pet Status",
      statusId: this.pet.current_status,
      component: "Status" + this.pet.current_status + "Icon",
    };
  },
  computed: {
    petStatusDropdownState() {
      return this.$store.state.petStatusDropdownState;
    },
    role() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ddfalse() {
      this.dropdown = false;
    },

    selectStatus(i, v, t, a) {
      // console.log(i, v, t, a);
      this.status = i;
      this.statusId = a;
      this.component = v;
      this.$store.commit("petStatusDropdown", { i, v, t, a });
    },
  },
};
</script>

<style></style>
