<template>
  <div class="flex flex-col justify-center items-center">
    <HeaderGlobal :titlemenu2="$t('pet_record_title')" />
    <!-- <div class="w-11/12 mt-20 text-left font-proximaMedium">
      <h3 class="mb-4 text-2xl">
        {{ $t("pet_record_overview_no_pets_title") }}
      </h3>
      <h5 class="mt-12 mb-1 text-xs">
        {{ $t("pet_record_overview_no_pets_info_text") }}
      </h5>
      <SelectOptions class="w-8/12 lg:w-6/12 mt-2" />
    </div> -->

    <div
      class="w-full mt-2 pl-0 md:pl-10 pt-8 pb-8 text-left bg-lightBlue font-proximaMedium rounded-br-2xl"
    >
      <!-- <h3 class="mb-6 text-2xl">
        {{ $t("pet_record_overview_guest_with_pets_title") }}
      </h3> -->
      <div v-if="petTab2.length > 0">
        <ul class="flex flex-wrap">
          <li
            class="w-4/12 mb-6 flex justify-start items-start"
            v-for="(pet, i) in petTab2"
            :key="i"
          >
            <PetoverviewEachFriend :pet="pet" />
            <!-- <div class="flex flex-col items-center cursor-pointer">
              <img
                class="rounded-full w-24 h-24"
                :src="pet.picture"
                :alt="pet.name"
              />
              <span class="pt-2 text-sm font-proximaLight">{{ pet.name }}</span>
            </div> -->
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import SelectOptions from "../forms/SelectOptions.vue";
import HeaderGlobal from "../header/HeaderGlobal.vue";
import PetoverviewEachFriend from "./PetoverviewEachFriend.vue";
import AddIcon from "../icons/AddIcon.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    SelectOptions,
    HeaderGlobal,
    AddIcon,
    PetoverviewEachFriend,
  },
  data() {
    return {
      petType: ["Dog", "Cat", "Ferret", "Bird", "Other"],
      showAddpets: false,
    };
  },
  computed: {
    ...mapGetters({
      petRecords: "petRecord/getPetRecords",
    }),
    petoverviewTab() {
      console.log(this.$store.state.petoverviewTab);
      return this.$store.state.petoverviewTab;
    },

    petTab2() {
      return this.petRecords.filter((item) => item.type == 3);
    },
    async mounted() {
      this.getPetRecords(localStorage.getItem("email"));
    },
  },
  methods: {
    ...mapActions({
      signIn: "auth/signIn",
      signInAfterLogin: "auth/signInAfterLogin",
      getPetRecords: "petRecord/getPetRecords",
    }),
    addPet() {
      console.log("add pet");
    },
  },
};
</script>

<style lang="scss" scoped>
.selectpets:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid white;
  left: -8px;
  top: 40px;
}
</style>