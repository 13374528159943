<template>
  <div class="flex flex-col items-center cursor-pointer relative">
    <div
      class="flex flex-col items-center cursor-pointer relative"
      @click="routeTo(petu)"
    >
      <img
        class="rounded-full w-24 h-24"
        :src="pet.profile_picture"
        :alt="pet.callName"
      />
      <!-- routineoverview/public/ -->
      <span class="pt-2 text-sm font-proximaLight">{{ petu.callName }}</span>
    </div>
    <div class="absolute -left-4">
      <img
        @click="dropdown = !dropdown"
        v-click-outside="ddfalse"
        class="h-4 cursor-pointer"
        src="@/assets/images/hamburger-icon.png"
        alt="hamburger-icon"
      />

      <div
        v-if="dropdown"
        class="w-44 mt-2 py-6 bg-white rounded-tr-lg z-10 relative"
      >
        <!--  <h5 class="pl-4 pb-2" @click="goTo('RecentActivity', petu)">
          {{ $t("pet_dropdown_menu_recent_activity") }}
        </h5> -->
        <!-- <ul>
            <li
              class="py-2 pl-4 cursor-pointer font-proximaLight border-transparent border-r-4 hover:bg-primary hover:text-white hover:border-r-4 hover:border-orange"
              @click="goTo(petu)"
            >
              {{ $t("pet_dropdown_menu_care_blog_entry") }}
            </li>
          </ul> -->
      </div>
    </div>

    <!--  <div class="bg-primary rounded-full p-1 absolute bottom-6 right-0">
      <component
        :is="whichIcon"
        v-if="petu.current_status != 100"
        width="26"
        height="26"
        iconColor="#fff"
      />
    </div> -->

    <!--  <div class="absolute -left-4">
      <img
        @click="dropdown = !dropdown"
        v-click-outside="ddfalse"
        class="h-4 cursor-pointer"
        src="@/assets/images/hamburger-icon.png"
        alt="hamburger-icon"
      />

      <div
        v-if="dropdown"
        class="w-44 mt-2 py-6 bg-white rounded-tr-lg z-10 relative"
      >
   
        <ul></ul>
      </div>
    </div> -->
    <AddNoteModals :petsOwned="[]" :diary="false" :pet="petu" />
  </div>
</template>

<script>
import ModalsPetStatus from "../modals/ModalsPetStatus.vue";
import AddNoteModals from "../careblog/modals/AddNoteModals.vue";
import Status100Icon from "../icons/Status102Icon.vue";
import Status101Icon from "../icons/Status101Icon.vue";
import Status102Icon from "../icons/Status107Icon.vue";
import Status103Icon from "../icons/Status103Icon.vue";
import Status104Icon from "../icons/Status104Icon.vue";
import Status105Icon from "../icons/Status105Icon.vue";
import Status106Icon from "../icons/Status106Icon.vue";
import Status107Icon from "../icons/Status107Icon.vue";
import Status108Icon from "../icons/Status108Icon.vue";
import Status109Icon from "../icons/Status109Icon.vue";
import Status110Icon from "../icons/Status110Icon.vue";
import ModalsConfirmPetStatusVue from "../modals/ModalsConfirmPetStatus.vue";
import ModalsDatePetDeath from "../modals/ModalsDatePetDeath.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  props: { pet: {} },
  components: {
    ModalsPetStatus,
    AddNoteModals,
    ModalsConfirmPetStatusVue,
    ModalsDatePetDeath,
    SubmitButton,
    BorderedButton,
    Status100Icon,
    Status101Icon, //ForadoptIcon,
    Status102Icon, //ForsaleIcon,
    Status103Icon, //Status103Icon
    Status104Icon, //InboardingIcon
    Status105Icon, //IntransitIcon,
    Status106Icon, //Transfert Pending,
    Status107Icon,
    Status108Icon, //LostIcon,
    Status109Icon,
    Status110Icon, //DeceasedIcon,
  },
  data() {
    return {
      dropdown: false,
      modalsConfirmPetStatus: false,
      petStatusModals: false,
      modalsPetDeath: false,
      petu: this.pet,
      modalTitle: "",
      modalBody: "",
      petStatusModals: false,
    };
  },

  computed: {
    whichIcon() {
      return "Status" + this.pet.current_status + "Icon";
    },
    petStatusComp() {
      return this.$store.state.petStatusModalsState;
    },
    petStatusDropdownComp() {
      return this.$store.state.petStatusDropdownState;
    },
  },

  methods: {
    ...mapActions({
      signIn: "auth/signIn",
      signInAfterLogin: "auth/signInAfterLogin",
      updatePetStatus: "petRecord/updatePetStatus",
    }),
    openPetStatusModals() {
      this.petStatusModals = true;
      this.$store.commit("petStatusModals", true);
    },
    closePetStatusModals() {
      this.petStatusModals = false;
      this.$store.commit("petStatusModals", false);
    },

    ddfalse() {
      this.dropdown = false;
    },

    goTo(pet) {
      this.$store.commit("careBlogDiaryModals", true);
      //localStorage.setItem("pet", JSON.stringify(pet));
      //this.$router.push({ path: "/routineoverview/public/" + pet.petID });
      //this.$router.push({ path: "/careblog" });
    },

    async routeTo(pet) {
      // Put the pet object into storage
      localStorage.setItem("pet", JSON.stringify(pet));
      console.log("+++++++++++ ", pet.private);
      pet.private
        ? this.$router.push({
            path: "/publicprofile/privateaccountsettingview",
          })
        : this.$router.push({ path: "/publicprofile/contactview" });
    },
  },
};
</script>

<style></style>
