<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div
        class="modal-container md:w-10/12 lg:w-6/12 flex flex-col justify-center items-center"
        >
        <div class="flex flex-col justify-center items-center">
            <FormError :errors="getError" />
            <h3 class="text-xl mb-3">Change Pet Status</h3>
          </div>

          <div
            class="w-full md:w-10/12 flex justify-center mb-0 font-proximaMedium"
          >
            <SelectPetStatus
              class="w-full md:w-6/12 mt-2"
              :pet="pet"
              :transition="transitions"
              @current_status="getStatus"
              ref="child"
              @close="$emit('close')"
            />
          </div>
          <div
            class="modal-footer w-full flex md:flex-row justify-center items-center"
          >
            <div class="w-11/12 md:w-4/12 lg:w-4/12">
              <slot name="leftBtn">
                <BorderedButton
                  class="md:mr-1"
                  :title="$t('global_header_clear_status')"
                  borderColor="border-mediumGrey"
                  textCl="text-darkGrey"
                  paddingX="px-8"
                  @click.native="closePetStatusModals"
                />
              </slot>
            </div>
            <div class="w-11/12 md:w-4/12 lg:w-4/12">
              <slot name="rightBtn">
                <SubmitButton
                  class="md:ml-1"
                  :title="pet.callName"
                  background="bg-success"
                  paddingX="px-8"
                />
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import SelectPetStatus from "../forms/selecticons/SelectPetStatus.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import FormError from "../warning/FormError.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    show: Boolean,
    pet: {},
  },
  components: {
    SelectPetStatus,
    SubmitButton,
    BorderedButton,
    FormError,
  },

  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      transitions: "petRecord/getTransitions",
      getError: "petRecord/getError",
    }),
    petStatusDropdownState() {
      return this.$store.state.petStatusDropdownState;
    },
  },

  mounted() {
    /* var retrievedObject = localStorage.getItem("pet");
    this.pet = JSON.parse(retrievedObject);
    console.log(this.pet); */

    this.getTransitions(this.pet.current_status);
  },

  methods: {
    ...mapActions({
      updatePetStatus: "petRecord/updatePetStatus",
      getTransitions: "petRecord/getTransitions",
    }),
    async getStatus(value) {
      //this.$emit("current_status", value);

      this.pet.current_status = value;
      console.log(
        "-------this.petu.current_status---------- : ",
        this.pet.current_status
      );

      this.getTransitions(this.petu.current_status);
      console.log("allowedPetRecordStatusTransition 2 : ", this.petu.callName);
    },
    /*  getStatus(value) {
      this.$emit("current_status", value);
      //console.log("modalsPetstatus--------", value);
    }, */
    closePetStatusModals() {
      this.$store.commit("petStatusModals", false);
    },
    // submitForm() {
    //   this.$refs.child.submitForm();
    //   console.log("ini log");
    //   this.closePetStatusModals();
    // },
  },
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>