<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div
          class="modal-container md:w-8/12 lg:w-5/12 flex flex-col justify-center items-center"
        >
          <div class="flex flex-col justify-center items-center">
            <h3 slot="actionQuestion" class="text-xl">{{ modalTitle }}</h3>
            <br />
            <p slot="actionText" class="text-sm">{{ modalBody }}</p>
          </div>

          <div
            class="modal-footer w-full flex md:flex-row justify-center items-center"
          >
            <BorderedButton
              class="mr-1"
              :title="$t('global_header_clear_status')"
              borderColor="border-mediumGrey"
              textCl="text-darkGrey"
              paddingX="px-8"
              width="w-11/12 md:w-4/12 lg:w-4/12"
              @click.native="$emit('closePetStatus')"
            />
            <SubmitButton
              class="ml-1"
              :title="$t('global_confirm')"
              background="bg-success"
              paddingX="px-8"
              width="w-11/12 md:w-4/12 lg:w-4/12"
              @click.native="submitForm"
            />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    show: Boolean,
    pet: {},
    trans: Number,
    modalTitle: String,
    modalBody: String,
  },
  components: {
    SubmitButton,
    BorderedButton,
  },

  data() {
    return {
      petType: [
        "In Boarding",
        "In Transit",
        "For Adoption",
        "For Sale",
        "Lost",
        "Deceased",
      ],
    };
  },
  methods: {
    ...mapActions({
      updatePetStatus: "petRecord/updatePetStatus",
    }),
    async submitForm() {
      const email = localStorage.getItem("email");
      const data = {
        trans: this.trans,
        petID: this.pet.petID,
        email: email,
      };

      this.updatePetStatus(data);

      this.$emit("closePetStatus");

      //-------- close -------------
    },
  },
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}
.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
