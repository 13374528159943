<template>
  <li
    class="
      py-3
      px-8
      font-proximaMedium
      text-left
      cursor-pointer
      border-transparent border-r-4
      hover:bg-primary hover:text-white hover:border-r-4 hover:border-orange
      flex
      items-center
    "
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <component :is="component" width="24" height="24" :iconColor="hoverComp" />
    <span class="ml-6 text-base">{{ title }}</span>
  </li>
</template>

<script>
import Status100Icon from "../../icons/Status103Icon.vue";
import Status101Icon from "../../icons/Status101Icon.vue";
import Status102Icon from "../../icons/Status102Icon.vue";
import Status103Icon from "../../icons/Status103Icon.vue";
import Status104Icon from "../../icons/Status104Icon.vue";
import Status105Icon from "../../icons/Status105Icon.vue";
import Status106Icon from "../../icons/Status106Icon.vue";
import Status107Icon from "../../icons/Status107Icon.vue";
import Status108Icon from "../../icons/Status108Icon.vue";
import Status109Icon from "../../icons/Status109Icon.vue";
import Status110Icon from "../../icons/Status110Icon.vue";

export default {
  props: ["title", "component"],
  components: {
    Status100Icon,
    Status101Icon,
    Status102Icon,
    Status103Icon,
    Status104Icon,
    Status105Icon,
    Status106Icon,
    Status107Icon,
    Status108Icon,
    Status109Icon,
    Status110Icon,
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    hoverComp() {
      if (this.hover) {
        return "#fff";
      } else {
        return "#354E57";
      }
    },
  },
};
</script>