<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div
          class="modal-container md:w-8/12 lg:w-5/12 flex flex-col justify-center items-center"
        >
          <div class="flex flex-col justify-center items-center">
            <h3 slot="actionQuestion" class="text-xl">
              Our sincerest condolences!
            </h3>

            <date-picker
              type="date"
              format="DD/MM/YYYY"
              v-model="dateDeath"
              @change="onChange()"
              placeholder="Date of pet's death"
              class="w-full rounded-sm my-1 text-sm"
            ></date-picker>

            <p slot="actionText" class="text-sm">
              Please be aware that once your pet’s status is changed to
              “deceased”, no more changes can be made to the data (you can still
              add photos)!
            </p>
          </div>

          <div
            class="modal-footer w-full flex md:flex-row justify-center items-center"
          >
            <BorderedButton
              class="mr-1"
              :title="$t('global_header_clear_status')"
              borderColor="border-mediumGrey"
              textCl="text-darkGrey"
              paddingX="px-8"
              width="w-11/12 md:w-4/12 lg:w-4/12"
              @click.native="$emit('closePetDeath')"
            />
            <SubmitButton
              class="ml-1"
              :title="$t('global_confirm')"
              background="bg-success"
              paddingX="px-8"
              width="w-11/12 md:w-4/12 lg:w-4/12"
              @click.native="submitForm"
            />
          </div>
        </div>
      </div>
      <ModalsPetConfirmeIdentity
        v-if="dateOfDeath != null"
        :show="petConfirmeIdentityModalsComp"
        @closePetConfirmeIdentity="closeModals"
        :pet="petu"
        :transitionId="transitionId"
        :dateOfDeath="dateOfDeath"
        :message="message"
      />
    </div>
  </Transition>
</template>

<script>
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import ModalsPetConfirmeIdentity from "./ModalsPetConfirmeIdentity.vue";
import { sendConfirmIdentityOtp } from "../../services/petRecordStatusService";

export default {
  props: {
    show: Boolean,
    pet: {},
    transitionId: Number,
  },
  components: {
    DatePicker,
    SubmitButton,
    BorderedButton,
    ModalsPetConfirmeIdentity,
  },

  data() {
    return {
      dateDeath: null,
      dateOfDeath: null,
      message: "",
      petType: [
        "In Boarding",
        "In Transit",
        "For Adoption",
        "For Sale",
        "Lost",
        "Deceased",
      ],
      petu: this.pet,
    };
  },

  computed: {
    petConfirmeIdentityModalsComp() {
      return this.$store.state.petConfirmeIdentityModalsState;
    },
  },
  methods: {
    onChange() {
      this.dateOfDeath = moment(String(this.dateDeath)).format("DD/MM/YYYY");
      console.log(this.dateOfDeath);
    },
    closePetConfirmeIdentity(i) {
      this.$store.commit("petConfirmeIdentityModals", false);
    },
    async submitForm() {
      //this.$refs.child.submitForm();
      const { data } = await sendConfirmIdentityOtp(
        localStorage.getItem("email"),
        "+447777777777",
        "EN",
        4
      );
      this.message = data.message;

      this.$store.commit("petConfirmeIdentityModals", true);
      //sendConfirmIdentityOtp(email_address, phone_number, lng, type)
    },
    closeModals() {
      this.$emit("closePetDeath");
      this.$store.commit("petConfirmeIdentityModals", false);
    },
  },
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}
.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.mx-datepicker-popup {
  z-index: 12345;
}
</style>
